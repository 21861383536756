import axios from '@/axios-client.js';
import store from '../store';


const baseUrl = store.state.configuration.VUE_APP_DJANGO_API_BASE;

const userAPI = {
  async signup(data, url) {
    try {
      const response = await axios.post(url || `${baseUrl}v2/users/`, data);
      return response;  // Retourne directement la réponse si succès
    } catch (err) {
      console.error('Erreur lors de l\'inscription :', err.response || err);
      return err.response || { status: 500, data: { detail: 'Erreur inconnue' } };  // 👈 Retourne la réponse d'erreur si disponible
    }
  },
};


export default userAPI;
